import { Button, message } from "antd";
import $diverProjectManagement from "../../../../store/manage-driver-project";
import { useParams } from "react-router-dom";
import Haul from "../../../../helpers/haulAssignmentHelper";
import Loader from "../../../primitives/Loader";
import useHauls from "../../../../api/queries/useHauls";
import Assignment from "../../../../helpers/assignmentHelper";
import useUpdateTrip from "../../../../api/mutations/useUpdateTrip";
import useGeoLocation from "../../../../api/hooks/useGeoLocation";
import GeoLocationHelper from "../../../../helpers/geoLocationHelper";
import Toastr from "toastr";
import { JobStatus, TripStatus } from "../../../../enums/status";
import JobHelper from "../../../../helpers/jobHelper";
import { useFormikContext } from "formik";
import DriverTripStatusMiddleware from "../../../core/DriverTripStatusMiddleware";

const ArrivedAtQuarryFormPhase = () => {
  const { assignment_id } = useParams();
  const form = useFormikContext();

  const { location: currentLocation, error, fetchLocation } = useGeoLocation();

  const { data: hauls, isLoading: isHaulsLoading } = useHauls({
    assignment_id,
  });

  const { mutate: updateTripStatus, isPending: isUpdatePending } =
    useUpdateTrip({
      onSuccess: () => {
        $diverProjectManagement.actions.nextPhase();
      },
    });

  if (isHaulsLoading) {
    return <Loader />;
  }

  const haulsData = hauls[0];
  const haul = new Haul(haulsData);
  const jobHelper = new JobHelper(haulsData.job);
  const isContractorMaterialSource = jobHelper.isContractorMaterialSource;
  const contractorMaterialSourceGps = jobHelper.getMaterialSourceGpsCoordinates;
  const assignmentData = haul.assignmentById(assignment_id);
  const assignment = new Assignment(assignmentData);
  const currentTrip = assignment.getTripByAssigmentId(assignment_id);

  const getCoordinatesToCheckWith = () => {
    if (isContractorMaterialSource && jobHelper.isImportJob) {
      return {
        lat: contractorMaterialSourceGps.lat,
        lng: contractorMaterialSourceGps.lng,
      };
    }
    if (jobHelper.isExportJob) {
      return {
        lat: jobHelper.gpsCoordinates.lat,
        lng: jobHelper.gpsCoordinates.lng,
      };
    }
    return {
      lat: assignment.mineCoordinates.lat,
      lng: assignment.mineCoordinates.lng,
    };
  };

  const validateUserLocation = () => {
    const { lat, lng } = getCoordinatesToCheckWith();
    return GeoLocationHelper.isLocationInRadius(
      {
        lat: currentLocation.latitude,
        lng: currentLocation.longitude,
      },
      {
        lat,
        lng,
      },
      1000
    );
  };

  const handleSubmit = async () => {
    try {
      await fetchLocation();
    } catch (error) {
      Toastr.error(
        "Turn On Location Services to Allow TruckingEZ to Determine Your Location"
      );
      return;
    }

    if (!validateUserLocation()) {
      Toastr.error("Location Unreached");
      return;
    }

    const payload = {
      trip_id: currentTrip?._id,
      status: TripStatus.ARRIVED_AT_QUARRY,
    };
    updateTripStatus(payload);
  };

  let status = currentTrip?.status;

  if (jobHelper.isHourlyJob && status === TripStatus.STARTED) {
    status = TripStatus.STARTED_HOURLY_JOB;
  }

  if (haul.isExpired) {
    status = JobStatus.EXPIRED;
  }

  return (
    <DriverTripStatusMiddleware status={status}>
      <div className="flex justify-center">
        <div className="flex flex-col justify-center items-center gap-8">
          <iframe
            title="map"
            width="100%"
            id="gmap_canvas"
            className="rounded w-96 h-96 "
            src={`https://maps.google.com/maps?q=${currentLocation.latitude},${currentLocation.longitude}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
          ></iframe>
          <Button onClick={handleSubmit} loading={isUpdatePending}>
            Arrived at Source
          </Button>
        </div>
      </div>
    </DriverTripStatusMiddleware>
  );
};

export default ArrivedAtQuarryFormPhase;
