import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { invalidateQueries, queryClient } from "../../api/queryClient";
import { Queries } from "../../api/queries";
import DateTimeHelper from "../../utils/timezone";
import { JobStatus, TripStatus } from "../../enums/status";
import Toaster from "toastr";

// Component imports
import DriverTripDropdown from "../../components/primitives/DriverTripDropdown";
import ProjectProgressInfo from "../../components/primitives/ProjectProgressInfo";
import DriverTripStatusMiddleware from "../../components/core/DriverTripStatusMiddleware";
import Timer from "../../components/primitives/Timer";

// Helper imports
import HaulAssignmentHelper from "../../helpers/haulAssignmentHelper";
import AssignmentHelper from "../../helpers/assignmentHelper";
import JobHelper from "../../helpers/jobHelper";
import HourlyTripHelper from "../../helpers/hourlyTripHelper";

// Hook imports
import useCreateTrip from "../../api/mutations/useCreateTrip";
import useUpdateTrip from "../../api/mutations/useUpdateTrip";
import useInitiateHourlyTrip from "../../api/mutations/useInitiateHourlyTrip";
import usePauseTrip from "../../api/mutations/usePauseTrip";
import useResumeTrip from "../../api/mutations/useResumeTrip";
import useGeoLocation from "../../api/hooks/useGeoLocation";

const DriverProjectDetails = ({ hauls }) => {
  const navigate = useNavigate();
  const { assignment_id } = useParams();
  const [isRepeatClicked, setIsRepeatClicked] = useState(false);
  const [isInvalidating, setIsInvalidating] = useState(false);

  const { location } = useGeoLocation();

  const invalidateWithLoading = async () => {
    setIsInvalidating(true);
    await invalidateQueries([Queries.hauls]);
    setIsInvalidating(false);
  };

  const { mutateAsync: startTrip, isPending } = useCreateTrip({
    onSuccess: async () => {
      await invalidateWithLoading();
    },
  });

  const { mutateAsync: initiateHourlyTrip, isPending: initiatingHourlyTrip } =
    useInitiateHourlyTrip({
      onSuccess: async () => {
        await invalidateWithLoading();
      },
    });

  const { mutateAsync: pauseHourlyTrip, isPending: tripPausing } = usePauseTrip(
    {
      onSuccess: async () => {
        await invalidateWithLoading();
      },
    }
  );

  const { mutateAsync: resumeHourlyTrip, isPending: resumingTrip } =
    useResumeTrip({
      onSuccess: async () => {
        await invalidateWithLoading();
        setPaused(false);
      },
    });

  const { mutateAsync: updateTrip } = useUpdateTrip({
    onSuccess: async () => {
      if (isRepeatClicked) {
        onClickStartProject();
      } else {
        await invalidateWithLoading();
        navigate(`/driver/projects/${assignment_id}`);
      }
    },
  });

  const onClickStartProject = async () => {
    let defaultCapacity = 20;
    const remainingQuantity = haulHelper.remainingQuantity;
    const loadQuantity =
      remainingQuantity > defaultCapacity ? defaultCapacity : remainingQuantity;

    if (remainingQuantity <= 0) {
      Toaster.error(
        "Trip initiation failed: Load transfer is fully completed."
      );
      return;
    }

    const payload = {
      job_assignment_id: assignment_id,
      load_quantity: loadQuantity,
      trip_date: moment().format("YYYY-MM-DD"),
    };

    startTrip(payload);
  };

  const repeat = () => {
    setIsRepeatClicked(true);
    if (currentTrip) {
      const payload = {
        trip_id: currentTrip?._id,
        status: TripStatus.COMPLETED,
      };
      updateTrip(payload);
    } else {
      onClickStartProject();
    }
  };

  const doneForTheDay = async () => {
    setIsRepeatClicked(false);
    if (currentTrip) {
      const payload = {
        trip_id: currentTrip?._id,
        status: TripStatus.COMPLETED,
      };
      updateTrip(payload);
    } else {
      navigate(-1);
    }
  };

  const haulsData = hauls[0];
  const haulHelper = new HaulAssignmentHelper(haulsData);
  const assignment = haulHelper.assignmentById(assignment_id);
  const assignmentHelper = new AssignmentHelper(assignment);
  const jobHelper = new JobHelper(haulHelper.job);

  const isHourlyJob = jobHelper.isHourlyJob;
  const currentTrip = assignmentHelper.getTheCurrentTrip({ isHourlyJob });
  const hourlyTripHelper = new HourlyTripHelper(currentTrip);

  const [paused, setPaused] = useState(hourlyTripHelper.isTripPaused);

  useEffect(() => {
    setPaused(hourlyTripHelper.isTripPaused);
  }, [hourlyTripHelper.isTripPaused]);

  const { project_name, tons_completed, quantity } = haulHelper.job;
  const totalQuantity = parseInt(quantity);
  const isHourlyJobComplete =
    isHourlyJob && currentTrip?.status === TripStatus.COMPLETED;

  let status = currentTrip?.status;

  if (isHourlyJob && status === TripStatus.STARTED) {
    status = TripStatus.STARTED_HOURLY_JOB;
  }

  if (haulHelper.isExpired) {
    status = JobStatus.EXPIRED;
  }

  const renderHourlyJobControls = () => {
    if (isHourlyJob) {
      return assignmentHelper.isAssignmentStarted() ? (
        <div
          className={`flex-1 flex justify-center gap-8 ${
            isInvalidating && "pointer-events-none"
          }`}
        >
          {isHourlyJobComplete && (
            <Button
              loading={resumingTrip}
              style={{
                borderRadius: "5px",
                backgroundColor: "#6F55FF",
                color: "#FFFFFF",
              }}
              className=" pointer-events-none"
            >
              Job Complete
            </Button>
          )}

          {!isHourlyJobComplete && hourlyTripHelper.sessions.length > 0 && (
            <React.Fragment>
              {paused ? (
                <Button
                  disabled={resumingTrip}
                  onClick={() => {
                    resumeHourlyTrip({
                      trip_id: currentTrip._id,
                      start_time: DateTimeHelper.getCurrentUTCISOstring(),
                    });
                  }}
                >
                  Resume
                </Button>
              ) : (
                <Button
                  disabled={tripPausing}
                  onClick={() => {
                    setPaused(true);
                    pauseHourlyTrip({
                      trip_id: currentTrip._id,
                      end_time: DateTimeHelper.getCurrentUTCISOstring(),
                    });
                  }}
                >
                  Pause
                </Button>
              )}

              <Button
                disabled={tripPausing}
                onClick={() => {
                  setPaused(true);
                  pauseHourlyTrip({
                    trip_id: currentTrip._id,
                    end_time: DateTimeHelper.getCurrentUTCISOstring(),
                    operation: "complete",
                  });
                }}
              >
                Done For the Day
              </Button>
            </React.Fragment>
          )}
        </div>
      ) : (
        <div className="flex-1 flex justify-center">
          <Button
            onClick={() => {
              initiateHourlyTrip({
                job_assignment_id: assignment_id,
                trip_date: moment().format("YYYY-MM-DD"),
              });
            }}
            loading={initiatingHourlyTrip}
          >
            Start Project
          </Button>
        </div>
      );
    }
  };

  const renderNonHourlyJobControls = () => {
    if (!isHourlyJob) {
      return assignmentHelper.isAssignmentStarted() ? (
        <div className="flex-1 flex justify-center gap-8">
          {haulHelper.status.toLowerCase() !==
            TripStatus.COMPLETED.toLowerCase() && (
            <Button
              loading={isPending}
              onClick={() => {
                repeat();
              }}
            >
              Repeat
            </Button>
          )}
          {currentTrip && (
            <Button
              onClick={() => {
                doneForTheDay();
              }}
            >
              Done For the Day
            </Button>
          )}
        </div>
      ) : (
        <div className="flex-1 flex justify-center">
          <Button onClick={onClickStartProject} loading={isPending}>
            Start Project
          </Button>
        </div>
      );
    }

    return null;
  };

  return (
    <DriverTripStatusMiddleware status={status}>
      <div className="flex justify-center">
        <div className="space-y-8 w-96 h-96">
          <div className="flex justify-center">
            <h1 className="text-xl capitalize text-center">
              Project - {project_name}
            </h1>
          </div>
          {isHourlyJob ? (
            <Timer
              initialSeconds={hourlyTripHelper.calculateEffectiveTripTime()}
              pause={paused}
            />
          ) : (
            <div className="space-y-4">
              <ProjectProgressInfo
                percent={haulHelper.completedPercentage}
                total={totalQuantity}
                completed={tons_completed}
              />
              <div className="grid gap-4 grid-cols-1">
                <DriverTripDropdown
                  titleOne={assignmentHelper.driverName}
                  tonsCompleted={assignmentHelper.totalLoadCompleted}
                />
              </div>
            </div>
          )}
          {!haulHelper.isExpired ? (
            <div className="">
              {isHourlyJob
                ? renderHourlyJobControls()
                : renderNonHourlyJobControls()}
            </div>
          ) : (
            <div className="flex justify-center">
              <Button
                loading={resumingTrip}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#6F55FF",
                  color: "#FFFFFF",
                }}
                className=" pointer-events-none"
              >
                Job Complete
              </Button>
            </div>
          )}{" "}
          <div className="flex justify-center">
            <div className="flex flex-col md:flex-row justify-center mt-8 gap-8">
              <iframe
                title="map"
                width="100%"
                id="gmap_canvas"
                className="rounded aspect-square"
                src={`https://maps.google.com/maps?q=${jobHelper.gps}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </DriverTripStatusMiddleware>
  );
};

export default DriverProjectDetails;
