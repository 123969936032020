import { useState, useEffect } from "react";

const useGeoLocation = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const userAgent = navigator.userAgent;
  const isFromFlutterApp = userAgent.includes("flutter-inappwebview");

  const handleSuccess = (position) => {
    setLocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
    setError(null);
    setLoading(false);
  };

  const handleError = (error) => {
    setError({
      code: error.code,
      message: error.message,
    });
    setLoading(false);
  };

  const fetchLocation = async () => {
    setLoading(true);
    try {
      const newLocation = await requestLocation();
      setLocation(newLocation);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const requestLocation = () => {
    return new Promise((resolve, reject) => {
      if (isFromFlutterApp) {
        window.locationFunction = (latitude, longitude) => {
          if (latitude && longitude) {
            resolve({ latitude, longitude });
          } else {
            reject({
              code: 0,
              message: "Failed to get location",
            });
          }
        };
        window.flutter_inappwebview.callHandler("NativeHandler", "getLocation");
      } else {
        navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
      }
    });
  };

  useEffect(() => {
    fetchLocation();
  }, []);

  return { location, error, loading, fetchLocation };
};

export default useGeoLocation;
